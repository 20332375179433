import { useEffect, useState } from 'react';
import { FormCheck } from 'react-bootstrap';
import { ResponsiveContainer, LineChart, Line, Legend, XAxis, YAxis, Tooltip } from 'recharts';
import './Chart.css'

const Chart = ({ data }) => {
    const [lines, setLines] = useState([]);
    const [dataChart, setDataChart] = useState([]);

    useEffect(() => {
        data.forEach((row) => {
            row.active = true;
        });

        setLines(data);
    }, [data]);

    useEffect(() => {
        mapData(lines);
    }, [lines]);

    const displayLines = () => {
        return lines
            .filter(row => row.active)
            .map((row) => {
                return <Line key={row.label} type="monotone" name={row.label} dataKey={row.key} stroke={row.color} active={row.active} />
            });
    };

    const mapData = (array) => {
        const rows = [];
        const dataArray = [];

        array.forEach((row) => {
            Object.keys(row.data).forEach((r) => {
                if (!rows[r]) {
                    rows[r] = { name: r };
                }

                rows[r][row.key] = row.data[r];
            });
        });

        Object.keys(rows).forEach((r) => {
            dataArray.push(rows[r]);
        });

        setDataChart(dataArray);
    }

    const renderLegend = (props) => {
        return (
            <ul className='recharts-default-legend'>
                {
                    lines.map((entry, index) => (
                        <li inline="true" className='recharts-legend-item' key={`item-${index}`}>
                            <FormCheck inline type='checkbox' defaultChecked={entry.active} onChange={(e) => { changeLegendHandler(e, entry) }} />
                            <span className='recharts-legend-item-text' style={{ color: entry.color }}>{entry.label}</span>
                        </li>
                    ))
                }
            </ul>
        );
    }

    const changeLegendHandler = (event, entry) => {
        let r = lines.map((row) => {
            if (row.key === entry.key) {
                row.active = event.target.checked;
            }

            return row;
        });

        setLines(r);
    }

    return (
        <ResponsiveContainer width="100%" height={400}>
            <LineChart width={500} height={300} data={dataChart}>
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend content={renderLegend} />
                {displayLines()}
            </LineChart>
        </ResponsiveContainer>
    )
}

export default Chart;