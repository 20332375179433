import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import TypeProvider from "../Type/TypeProvider";

export default function TypeInput({ token, typeError, onChange, emptyOption }) {
    const [type, setType] = useState();
    const [types, setTypes] = useState([]);

    useEffect(() => {
        TypeProvider({ token, setTypes });
    }, [token]);

    const displayOption = () => {
        if (types.length > 0 && !type && !emptyOption) {
            changeType(types[0]);
        }

        return types.map((row) => {
            return <option value={row} key={row}>{row}</option>
        });
    }

    const changeType = (value) => {
        setType(value);
        onChange(value);
    }

    return (
        <>
            <Form.Select onChange={(e) => { changeType(e.target.value) }} isInvalid={!!typeError}>
                {emptyOption ? <option></option> : ''}
                {displayOption()}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                {typeError}
            </Form.Control.Feedback>
        </>
    )
}