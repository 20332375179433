import jwtDecode from 'jwt-decode';

const AuthVerify = (props) => {
    const token = JSON.parse(localStorage.getItem("token"));

    if (token) {
        const jwt = jwtDecode(token);
        const exp = (jwt.exp * 1000) - 10000;

        if (exp < Date.now()) {
            props.setToken(null)
        }
    }
};

export default AuthVerify;