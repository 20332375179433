import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

const API_URL = process.env.REACT_APP_API_URL;

export default function Main({ token }) {
    const getDate = () => {
        const today = new Date();
        today.setDate((new Date()).getDate() - 1);

        return today.toISOString().slice(0, 10);
    }

    const [data, setData] = useState([]);
    const [date, setDate] = useState(getDate());

    const columns = [
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
        },
        {
            name: 'Number of links',
            selector: row => row.numberOfLinks,
            sortable: true,
        },
        {
            name: 'Total reviews',
            selector: row => row.totalReviews,
            sortable: true,
        },
        {
            name: 'Number of ratings',
            selector: row => row.numberOfRatings,
            sortable: true,
        },
        {
            name: 'Average rating',
            selector: row => row.averageRating.toFixed(2),
            sortable: true,
        },
        {
            name: 'Average length of content',
            selector: row => row.averageContent.toFixed(2),
            sortable: true,
        },
        {
            name: 'Average length of author',
            selector: row => row.averageAuthor.toFixed(2),
            sortable: true,
        },
        {
            name: 'Empty',
            selector: row => row.ratingEmpty,
            sortable: true,
            width: '80px'
        },
        {
            name: '1',
            selector: row => row.rating1,
            sortable: true,
            width: '60px'
        },
        {
            name: '2',
            selector: row => row.rating2,
            sortable: true,
            width: '60px'
        },
        {
            name: '3',
            selector: row => row.rating3,
            sortable: true,
            width: '60px'
        },
        {
            name: '4',
            selector: row => row.rating4,
            sortable: true,
            width: '60px'
        },
        {
            name: '5',
            selector: row => row.rating5,
            sortable: true,
            width: '60px'
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                paddingLeft: '1px', // override the cell padding for head cells
                paddingRight: '1px',
            },
        },
        cells: {
            style: {
                paddingLeft: '1px', // override the cell padding for data cells
                paddingRight: '1px',
            },
        },
    };

    useEffect(() => {
        sendRequest(token, date);
    }, [token, date]);

    const sendRequest = async function (t, d) {
        return await fetch(API_URL + ('/api/daily-report/:date').replace(":date", d), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + t
            }
        }).then(res => {
            if (res.ok) {
                return res;
            }
            throw new Error(res.status);
        }).then(data => data.json())
            .then(data => setData(data.results))
            .catch(e => console.log(e));
    }

    return (
        <>
            <Row>
                <Col><h3>Daily statistics</h3></Col>
            </Row>

            <Row>
                <Col xs={6} md={4}>
                    <Form>
                        <Form.Control type="date" placeholder="Date" value={date} onChange={e => setDate(e.target.value)} />
                    </Form>
                </Col>
                <Col>
                    <p><label>Date:</label> {date}</p>
                </Col>
            </Row>

            <Row className="mt-5">
                <Col>
                    <DataTable columns={columns} data={data} customStyles={customStyles} />
                </Col>
            </Row>
        </>
    );
}