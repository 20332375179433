import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';

const API_URL = process.env.REACT_APP_API_URL;

export default function Profile({ token }) {
    const [id, setId] = useState();
    const [email, setEmail] = useState();

    const fetchData = async function (t) {
        return await fetch(API_URL + '/api/profile/me', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + t
            }
        }).then(res => {
            if (res.ok) {
                return res;
            }
            throw new Error(res.status);
        }).then(data => data.json())
            .then(data => {
                setId(data.id);
                setEmail(data.email);
            })
            .catch(e => console.log(e));
    };

    useEffect(() => {
        fetchData(token);
    }, [token]);

    if (!id && !email) {
        return (<Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>)
    }

    return (
        <>
            <h3>Your profile</h3>
            <p>ID: {id}</p>
            <p>Email: {email}</p>
        </>
    )
}