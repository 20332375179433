import { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";

import './Result.css';

const API_URL = process.env.REACT_APP_API_URL;

export function Result({ token }) {
    const { linkId, queueId } = useParams();
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        fetchResults(token, linkId, queueId);
    }, [token, linkId, queueId]);

    const fetchResults = async (t, lId, qId) => {
        const path = API_URL + '/api/links/:linkId/links_queues/:queueId/results'.replace(':linkId', lId).replace(':queueId', qId);

        const response = await fetch(
            path,
            {
                methid: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + t
                },
            }
        ).then(data => data.json());

        setData(response['hydra:member']);
        setTotal(response['hydra:totalItems']);
    }

    const displayData = () => {
        return data.map(row =>
            <tr>
                <td>{row.id}</td>
                <td>{row.added}</td>
                <td>
                    <div style={{ height: '50px', overflow: 'hidden' }}>{row.result}</div>
                </td>
            </tr>
        );
    }

    return (<>
        <Row className="mt-5">
            <Col>
                <h5>Total results: {total}</h5>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Added</th>
                            <th>Reviews</th>
                        </tr>
                    </thead>
                    <tbody>{displayData()}</tbody>
                </Table>
            </Col>
        </Row>
    </>)
}