import { useEffect, useState } from "react";
import { Link as RouteLink } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import TypeInput from "./TypeInput";

const API_URL = process.env.REACT_APP_API_URL;

export default function Link({ token }) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [requestPage, setRequestPage] = useState(1);
    const [requestColumn, setRequestColumn] = useState('id');
    const [requestSortDirection, setRequestSortDirection] = useState('asc');
    const [type, setType] = useState('');

    useEffect(
        () => { fetchLinks(token, requestPage, requestColumn, requestSortDirection, type); },
        [token, requestPage, requestColumn, requestSortDirection, type]
    );

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
        },
        {
            name: 'URL',
            selector: row => <a href={row.url} rel="noreferrer" target="_blank">{row.url}</a>,
        },
        {
            name: 'Action',
            selector: row => <p>
                <Button variant="outline-primary" size="sm" onClick={e => deleteHandler(token, e, row.id)}>Delete</Button>
                <RouteLink to={getLinkToQueue(row.id)}>
                    <Button variant="outline-primary" size="sm">Queue</Button>
                </RouteLink>

            </p>,
        },
    ];

    const paginationComponentOptions = {
        noRowsPerPage: true,
    };

    const fetchLinks = async (t, page, col, sort, type) => {
        const order = (col && sort ? '&order[' + col + ']=' + sort : '');
        const typeQuery = (type ? '&type=' + type : '');

        const path = API_URL + '/api/links?page=:page'.replace(':page', page) + order + typeQuery;

        setLoading(true);

        const response = await fetch(path, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + t
            }
        }).then(data => data.json());

        setData(response['hydra:member']);
        setTotalRows(response['hydra:totalItems']);

        setLoading(false);
    };

    const handlePageChange = page => {
        setRequestPage(page);
    };

    const handleSort = async (column, sortDirection) => {
        if (column.name !== undefined && column.name.toLowerCase() !== requestColumn) {
            setRequestColumn(column.name.toLowerCase());
        }

        if (sortDirection !== requestSortDirection) {
            setRequestSortDirection(sortDirection);
        }
    };

    const deleteHandler = async (t, event, id) => {
        event.preventDefault();

        if (window.confirm("Are you sure?")) {
            const path = API_URL + '/api/links/{id}'.replace('{id}', id);

            await fetch(path, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + t
                }
            });

            await fetchLinks(t, requestPage, requestColumn, requestSortDirection, type);
        }
    }

    const getLinkToQueue = (id) => '/link/:id/queue'.replace(':id', id);

    return (
        <>
            <Row>
                <Col>
                    <h3>Links</h3>
                    <RouteLink to='create'>
                        <Button variant="outline-primary">Create Link</Button>
                    </RouteLink>
                </Col>
            </Row>

            <Row className="mt-2">
                <p>Filters:</p>
                <Col xs lg="4">
                    <TypeInput token={token} emptyOption="true" onChange={(e) => setType(e)} />
                </Col>
            </Row>

            <Row className="mt-5">
                <Col>
                    <DataTable
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        sortServer
                        onSort={handleSort}
                        defaultSortFieldId={1}
                    />
                </Col>
            </Row>
        </>
    )
}