import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import TypeInput from "./TypeInput";

const API_URL = process.env.REACT_APP_API_URL;

export default function CreateLink({ token }) {
    const navigate = useNavigate();
    const [url, setUrl] = useState();
    const [type, setType] = useState();
    const [urlError, setUrlError] = useState();
    const [typeError, setTypeError] = useState();

    const submitHandler = async (e) => {
        e.preventDefault();
        setUrlError();
        setTypeError();

        const response = await fetch(
            API_URL + '/api/links',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify({ type, url })
            }
        );

        if (!response.ok) {
            const error = await response.json();

            error.violations.forEach((row) => {
                if (row.propertyPath === 'url') {
                    setUrlError(row.message)
                }

                if (row.propertyPath === 'type') {
                    setTypeError(row.message)
                }
            });
        } else {
            return navigate('/link');
        }
    };

    return (
        <>
            <Row>
                <Col><h3>Create Link</h3></Col>
            </Row>

            <Row className="mt-5">
                <Col xs lg="6">
                    <Form onSubmit={submitHandler}>
                        <Form.Group className="mb-3">
                            <Form.Label>URL:</Form.Label>
                            <Form.Control type="text" placeholder="Enter Link" onChange={(e) => { setUrl(e.target.value) }} isInvalid={!!urlError} />
                            <Form.Control.Feedback type="invalid">
                                {urlError}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Type:</Form.Label>
                            <TypeInput token={token} typeError={typeError} onChange={(e) => setType(e)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Button type="submit">Save</Button>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </>
    )
}