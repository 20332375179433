import React, { useState } from 'react';

import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';

const API_URL = process.env.REACT_APP_API_URL;

export default function Login({ setToken }) {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState();

    const handleSubmit = async e => {
        e.preventDefault();

        const token = await loginUser({
            username,
            password
        });

        if (token) {
            setToken(token);
        }
    }

    async function loginUser(credentials) {
        return fetch(API_URL + '/api/login_check', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
            .then(res => {
                if (res.ok) {
                    return res;
                }
                throw new Error(res.status);
            })
            .then(data => data.json())
            .then(data => data.token)
            .catch(e => setError("Invalid credentials."));
    }

    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col md="auto">
                    {error ? <Alert variant={'danger'}>{error}</Alert> : ''}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" onChange={e => setUsername(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Login
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}