const API_URL = process.env.REACT_APP_API_URL;

export default async function TypeProvider({ token, setTypes }) {
    return await fetch(API_URL + "/api/type", {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }).then(res => {
        if (res.ok) {
            return res;
        }
        throw new Error(res.status);
    }).then(data => data.json())
        .then(data => setTypes(data))
        .catch(e => console.log(e));
}