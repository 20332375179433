import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import Main from './components/Dashboard/Main';
import Login from './components/Login/Login';
import useToken from './components/Login/useToken';
import Layout from './components/Dashboard/Layout';
import Profile from './components/Dashboard/Profile';
import NotFoundPage from './components/NotFoundPage';
import AuthVerify from './components/Auth/AuthVerify';
import TypeStatistics from './components/Type/TypeStatistic';
import { useEffect } from 'react';
import Link from './components/Link/Link';
import CreateLink from './components/Link/CreateLink';
import { LinkQueue } from './components/Link/LinkQueue';
import { Result } from './components/Link/Result';

function App() {
  const { token, setToken } = useToken();
  const checkToken = () => { AuthVerify({ setToken }) }

  useEffect(() => {
    checkToken();
    setInterval(() => {
      checkToken();
    }, 2000)
  });

  if (!token) {
    return <Login setToken={setToken} />
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout setToken={setToken} />}>
          <Route index element={<Main token={token} />} />
          <Route path="type" element={<TypeStatistics token={token} />} />
          <Route path="profile" element={<Profile token={token} />} />
          <Route path='link' element={<Link token={token} />} />
          <Route path='link/create' element={<CreateLink token={token} />} />
          <Route path='link/:linkId/queue' element={<LinkQueue token={token} />} />
          <Route path='link/:linkId/queue/:queueId/results' element={<Result token={token} />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
