import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

export function LinkQueue({ token }) {
    const { linkId } = useParams();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [requestPage, setRequestPage] = useState(1);
    const [requestColumn, setRequestColumn] = useState('id');
    const [requestSortDirection, setRequestSortDirection] = useState('asc');

    useEffect(() => {
        fetchQueue(token, linkId, requestPage, requestColumn, requestSortDirection);
    }, [token, linkId, requestPage, requestColumn, requestSortDirection]);

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Added',
            selector: row => row.added,
            sortable: true,
        },
        {
            name: 'TransformerId',
            selector: row => row.transformerId,
            sortable: true,
        },
        {
            name: 'Action',
            selector: row => <Link to={getLinkToResults(linkId, row.id)}>
                <Button variant="outline-primary" size="sm">Results</Button> 
            </Link>,
            sortable: true,
        },
    ];

    const fetchQueue = async (t, id, p, col, sort) => {
        const order = (col && sort ? '&order[' + col + ']=' + sort : '');
        const path = API_URL + '/api/links/:id/links_queues?page=:page'.replace(':id', id).replace(':page', p) + order;

        const response = await fetch(
            path,
            {
                methid: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + t
                },
            }
        ).then(data => data.json());

        setData(response['hydra:member']);
        setTotalRows(response['hydra:totalItems']);
        setLoading(false);

    }

    const handlePageChange = page => {
        setRequestPage(page);
    };

    const handleSort = async (column, sortDirection) => {
        if (column.name !== undefined && column.name.toLowerCase() !== requestColumn) {
            setRequestColumn(column.name.toLowerCase());
        }

        if (sortDirection !== requestSortDirection) {
            setRequestSortDirection(sortDirection);
        }
    };

    const getLinkToResults = (linkId, queueId) =>{
        return '/link/:linkId/queue/:queueId/results'.replace(':linkId', linkId).replace(':queueId', queueId);
    }

    return (<>
        <Row>
            <Col>
                <DataTable
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    sortServer
                    onSort={handleSort}
                    defaultSortFieldId={1}
                />
            </Col>
        </Row>
    </>
    )
}