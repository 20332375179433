import { Outlet, useNavigate } from "react-router-dom";
import { Container, Row, Col, Nav, Navbar, NavItem, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap'

export default function Layout({ setToken }) {
    const navigate = useNavigate();

    const logOutHandler = function () {
        setToken(null);

        return navigate('/');
    };

    return (
        <>
            <Navbar bg="light" expand="lg">
                <Container>
                    <Navbar.Brand href="/">Review Monitoring</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <NavItem>
                                <LinkContainer to="/">
                                    <Nav.Link>Home</Nav.Link>
                                </LinkContainer>
                            </NavItem>

                            <NavItem>
                                <LinkContainer to="/type">
                                    <Nav.Link>Type</Nav.Link>
                                </LinkContainer>
                            </NavItem>

                            <NavItem>
                                <LinkContainer to="/link">
                                    <Nav.Link>Links</Nav.Link>
                                </LinkContainer>
                            </NavItem>
                        </Nav>
                        <Nav>
                            <NavDropdown title="Account" id="basic-nav-dropdown">
                                <NavItem as={NavDropdown.Item}>
                                    <LinkContainer to="/profile">
                                        <Nav.Link>Profile</Nav.Link>
                                    </LinkContainer>
                                </NavItem>
                                <NavDropdown.Divider />
                                <NavItem as={NavDropdown.Item}>
                                    <Nav.Link onClick={logOutHandler}>Logout</Nav.Link>
                                </NavItem>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Container className="mt-2">
                <Row>
                    <Col>
                        <Outlet />
                    </Col>
                </Row>
            </Container>
        </>
    )
}