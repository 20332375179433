import { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import Chart from './Chart';
import TypeProvider from './TypeProvider';

const API_URL = process.env.REACT_APP_API_URL;

export default function TypeStatistics({ token }) {
    const [types, setTypes] = useState([]);
    const [type, setType] = useState(null);
    const [period, setPeriod] = useState(7);
    const [mainData, setMainData] = useState([]);
    const [averageData, setAverageData] = useState([]);
    const [ratingData, setRatingData] = useState([]);
    const [responseData, setResponseData] = useState([]);

    const fetchStats = async () => {
        const path = "/api/type-report/:type/:period".replace(':type', type).replace(':period', period);

        return await fetch(API_URL + path, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(res => {
            if (res.ok) {
                return res;
            }
            throw new Error(res.status);
        }).then(data => data.json())
            .then(data => data)
            .catch(e => console.log(e));
    }

    useEffect(() => {
        TypeProvider({token, setTypes});
    }, [token]);

    const getOptions = () => {
        const rows = [];

        types.forEach((row) => {
            rows.push(row);
        });

        if (types.length > 0 && !type) {
            setType(types[0]);
        }

        return rows.map((row) => {
            return <option value={row} key={row}>{row}</option>
        });
    };

    const showHandler = async (e) => {
        e.preventDefault();
        const data = await fetchStats();

        setMainData(data.main);
        setAverageData(data.average);
        setRatingData(data.ratings)
        setResponseData(data.responses);
    }

    return (
        <>
            <Row>
                <Col><h3>Type statistics</h3></Col>
            </Row>

            <Row>
                <Col xs={10} md={8}>
                    <Form.Group as={Row}>
                        <Form.Label column sm="2">Type:</Form.Label>
                        <Col sm="4">
                            <Form>
                                <Form.Select onChange={(e) => setType(e.target.value)}>
                                    {getOptions()}
                                </Form.Select>
                            </Form>
                        </Col>
                        <Form.Label column sm="2">Period:</Form.Label>
                        <Col sm="4">
                            <Form>
                                <Form.Select value={period} onChange={(e) => setPeriod(e.target.value)}>
                                    <option value="7">a week</option>
                                    <option value="30">a month</option>
                                    <option value="90">3 months</option>
                                    <option value="180">6 months</option>
                                    <option value="365">a year</option>
                                </Form.Select>
                            </Form>
                        </Col>
                    </Form.Group>
                </Col>
                <Col>
                    <Button variant="primary" type="submit" onClick={(e) => showHandler(e)}>Show</Button>
                </Col>
            </Row>

            <Row className="mt-5">
                <Col>
                    <h4>Main</h4>
                    <Chart data={mainData} />

                    <h4>Average</h4>
                    <Chart data={averageData} />

                    <h4>Rating</h4>
                    <Chart data={ratingData} />

                    <h4>Responses</h4>
                    <Chart data={responseData} />
                </Col>
            </Row>
        </>
    )
}